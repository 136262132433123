<script lang="ts">
	import { buttonSizeClass, type ButtonSize } from '$lib/utils/button-styles'
	import Spinner from '$lib/components/loading/Spinner.svelte'
	import { override } from '$lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'
	import type { Snippet } from 'svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		class?: string
		disabled?: boolean
		loading?: boolean
		label?: string | null
		priority?: 'primary' | 'secondary' | 'tertiary'
		theme?: 'brand' | 'dark' | 'light' | 'delete'
		size?: ButtonSize
		children: Snippet
		onclick: () => void
	}

	let {
		class: className,
		disabled = false,
		loading = false,
		label = null,
		priority = 'primary',
		theme = 'brand',
		size = 'md',
		onclick,
		children,
		...rest
	}: Props = $props()

	let sizeClass = $derived(buttonSizeClass(size))

	function handleOnClick() {
		onclick?.()
	}

	const sharedClasses =
		'flex flex-row items-center justify-center appearance-none transition-all hover:opacity-100 text-center rounded-md whitespace-nowrap leading-4 focus:outline-none touch-manipulation'

	let priorityClasses = $derived(
		priority == 'primary'
			? 'tracking-wide font-normal'
			: priority == 'secondary'
				? 'normal-case text-xl font-normal tracking-wide'
				: '',
	)

	let themeClasses = $derived(
		theme == 'brand'
			? 'hover:bg-brand-primary bg-brand-primary-75d text-white active:text-white disabled:bg-brand-gray-3 disabled:text-black disabled:from-transparent disabled:to-transparent disabled:cursor-not-allowed'
			: theme == 'dark'
				? 'hover:bg-brand-gray-5 bg-black border border-brand-gray-4 text-white active:text-white disabled:bg-brand-gray-3 disabled:text-brand-gray-5 active:bg-brand-gray-5'
				: theme == 'light'
					? 'bg-transparent hover:bg-white text-black border border-black active:text-black disabled:bg-brand-gray-3 disabled:text-brand-gray-5 active:bg-brand-gray-5'
					: theme == 'delete'
						? 'bg-red-700 hover:bg-red-600 text-white active:text-white disabled:bg-red-400 disabled:text-white active:bg-red-600'
						: '',
	)

	let computedClassName = $derived(twMerge(override(sharedClasses, className)))
</script>

<button
	onclick={stopPropagation(handleOnClick)}
	{...rest}
	{disabled}
	class={`${computedClassName}
          ${priorityClasses}
          ${themeClasses}
          ${sizeClass}
          ${className}
          `}
>
	{#if loading}
		<span class="pr-2 -my-1">
			<Spinner {size} embedded />
		</span>
	{/if}
	{@render children?.()}
	{#if label}
		{label}
	{/if}
</button>
